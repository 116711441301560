import React from "react";
import { Container, Breadcrumb, Row, Col, Button} from "react-bootstrap";
import {Link} from 'gatsby'
import PageWrapper from "../../components/PageWrapper";
import { Section, Post } from "../../components/Core";
import Header from "../../components/HeaderPro";
import Footer from "../../components/FooterPro";
import {Helmet} from "react-helmet";
import {StaticImage} from 'gatsby-plugin-image';
import ReactStars from "react-rating-stars-component";
import DataTable from '../../components/data/banquePro/banquePro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight} from '@fortawesome/free-solid-svg-icons'

function avis() {

	const go = '/go/?go=Qonto&url=https://lb.affilae.com/r/?p=5ce4f2a2b6302009e29d84f3&af=136&lp=https%3A%2F%2Fqonto.com%2Ffr%2F%3Futm_source%3Daffiliate%26utm_medium%3Dcpl%26utm_campaign%3Dbankbank'
	
	const star1 = {size: 22,value: 4.2,color: "grey",activeColor: "#ffc107",edit: false,};
	const star2 = {size: 22,value: 3,color: "grey",activeColor: "#ffc107",edit: false,};
	const star3 = {size: 22,value: 4.4,color: "grey",activeColor: "#ffc107",edit: false,};
	const star4 = {size: 22,value: 5,color: "grey",activeColor: "#ffc107",edit: false,};
	const star5 = {size: 22,value: 5,color: "grey",activeColor: "#ffc107",edit: false,};
	const star6 = {size: 22,value: 5,color: "grey",activeColor: "#ffc107",edit: false,}; 
	

    return (
        <>
        <PageWrapper footerDark>
    	<Helmet>
                <meta charSet="utf-8" />
                <title>Avis Qonto : à lire absolument avant d'ouvrir un compte pro</title>
                <meta name="description" content="
              Qonto est une néobanque pour les entreprises et indépendants. C’est un compte pro qui vient en complément ou qui peut remplacer votre banque pro traditionnelle." />
        </Helmet>
      	<Header />
      	<Section>
      	<Container style={{maxWidth: 1280}}>
		  <Breadcrumb id='breadyTop'>
			<Breadcrumb.Item> <Link to={`/`} style={{color: 'black'}}>Accueil</Link></Breadcrumb.Item>
			<Breadcrumb.Item> <Link to={`/banque-pro/`} style={{color: 'black'}}>Banque Pro</Link></Breadcrumb.Item>
			<Breadcrumb.Item active id='bready'>Avis Qonto</Breadcrumb.Item>
      	 </Breadcrumb>

	
	<Row>
		<Col sm={9}>  <h1 id='avish1' style={{color: 'rgb(41, 49, 61)'}}>Avis Qonto</h1>
          <h2 id='avish2' style={{marginLeft: 0, marginBottom: 25, color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Qonto est une néobanque pour les entreprises et indépendants. C’est un compte pro qui vient en complément ou qui peut remplacer votre banque pro traditionnelle.</h2>
	
 <a href={go} target='_blank' rel="noreferrer">
                <Button id="voirOffre" type="submit" className="btn-lg" style={{height: 60, backgroundColor: 'rgb(240 0 87)', borderColor: 'rgb(240 0 87)', color: 'white'}}>Offre Qonto <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Button>
                </a>

		  
		  </Col>
	
		<Col sm={3} id='logoAvis'>
		<a href={go} target='_blank' rel="noreferrer">
		<StaticImage
		  	src='../../images/qonto-logo.png'
			width={250}
			alt='logo qonto'
			placeholder='tracedSVG'
			/>
</a>
		</Col>
	</Row>

		 
<hr></hr>
<Row>
	<Col sm={4}>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Compte bancaire</p></Col>
			<Col> <ReactStars {...star1} /></Col>
		</Row>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Carte bancaire</p></Col>
			<Col><ReactStars {...star2} /></Col>
		</Row>
	</Col>
	<Col sm={4}>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Tarifs</p></Col>
			<Col><ReactStars {...star3} /></Col>
		</Row>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Application</p></Col>
			<Col><ReactStars {...star4} /></Col>
		</Row>
	</Col>
	<Col sm={4}>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Comptabilité</p></Col>
			<Col><ReactStars {...star5} /></Col>
		</Row>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Gestion</p></Col>
			<Col><ReactStars {...star6} /></Col>
		</Row>
	</Col>
</Row>
<hr></hr>
<Post>



		<h2>Compte Pro Qonto</h2>
		<p>Qonto a été crée pour les entreprises de 1 à 250 employés. Son offre a été scindé en 3 solutions :</p>
		<ul>
			<li>freelances, indépendant et autoentrepreneur</li>
			<li>entreprises et sociétés (SA, SAS, SASU, SARL..)</li>
			<li>les futurs créateurs d’entreprise</li>
		</ul>

<iframe id='videoYT'src="https://www.youtube.com/embed/GUtDtqSC--A" title="Avis Qonto" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>


<hr></hr>

<h2>Offre Freelance et Indépendant</h2>
<p>2 comptes disponibles pour les micro-entreprises et indépendants :</p>
<ul>
	<li>Solo One</li>
	<li>Solo Plus</li>
</ul>
<p>
Ces 2 comptes pro se différencie par la carte bancaire qui impacte la commission pour le paiement en devises, la limite de paiement par carte, le nombre de retraits d’espèces gratuits et les limites de retraits au distributeur sur 30 jours glissants.
</p>

<h2>Offre Entreprise</h2>
<p>Les entreprises disposent de 3 offres :</p>
<ul>
	<li>Standard</li>
	<li>Premium</li>
	<li>Corporate</li>
</ul>
<p>
Ces offres se différencient par le nombre d’utilisateurs et cartes physique ainsi que le nombre de transactions possible. Plus votre entreprises est grande et actives et plus les offres Premium et Corporate seront pour vous.
</p>
<div style={{marginLeft: -5}}>
<DataTable row={[12,13,14,18,19,20,24,25,26]} id='postTable'/>
<DataTable row={[12,13,14,18,19,20,24,25,26]} id='mobileTable'/>
</div>

<h3>Offre créateur d’entreprise</h3><p>

La création d’une nouvelle société nécessite le dépôt d’un capital social permettant l’immatriculation et l’obtention d’un Kbis et d’un numéro de SIREN. Qonto facilite cette création avec une offre pour futur entrepreneur.
</p><p>
Qonto propose 2 packs comprenant le dépôt de capital :
</p>
<ul>
	<li>Pack Solo</li>
	<li>Pack Standard</li>
</ul>

<p>
Ces 2 formules correspondent à un paiement annuel des offres Freelance et Entreprise ci-dessus. Vous retrouverez donc tous les mêmes tarifs bancaires de l’offre solo one pour les freelances et indépendants et l’offre standard pour entreprises. Payer de cette manière vous donnera une réduction respective de votre abonnement de 38€ et 98€.
</p><p>
Le tarif des opérations spéciales est le même pour tous les comptes.
</p>

<h2>Les services de Qonto</h2>
<p>
Qonto dispose d’une liste de services particulièrement poussée, notamment sur la gestion comptable de votre entreprise.
</p>
<div style={{maxWidth: 800, margin: 'auto'}}>
			<StaticImage
		  	src='../../images/qonto-site.png'
			width={800}
			alt='site qonto'
			placeholder='tracedSVG'
			id='pickky'
			/>
			</div>

			<div style={{maxWidth: 300, margin: 'auto', marginBottom : 50, marginTop: 50}}>
			<a href={go} target='_blank' rel="noreferrer">
	<Button id="voirOffre" type="submit" className="btn-lg" style={{height: 60, backgroundColor: 'rgb(240 0 87)', 
	borderColor: 'rgb(240 0 87)', color: 'white', fontSize: '1.25rem',
	fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
	}}>Services Qonto <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Button>
	</a>
	</div>


	<h3>Moyen de paiement</h3>
	<ul>
		<li>IBAN Français</li>
		<li>Cartes bancaires</li>
		<li>Cartes virtuelles</li>
		<li>Faire 200 virements en une seule opération avec un simple fichier .csv</li>
		<li>Annuler un virement</li>
		<li>Demander le remboursement d’un prélèvement</li>
		<li>Mise en place des prélèvements simplifié</li>
		<li>Encaissement de chèque simplifié</li>
	</ul>

<h3>Comptabilité simplifié</h3>
<li>Partenariats avec des experts comptables</li>
		<li>Accès “spécial” pour votre expert comptable</li>
		<li>Factures rattachés automatiquement à chaque transaction</li>
		<li>Automatisation de la TVA</li>
		<li>Visualisation de la TVA améliorée</li>
		<li>Notification lorsque votre relevé de compte est disponible</li>
		<li>Historique des frais cartes bancaires plus claire</li>

<h3>Gestion des collaborateurs</h3>
<li>Plateforme qui facilite la demande de justificatifs manquants</li>
		<li>Mode “demande” pour accélérer la demande de validation de virement</li>

	

<div style={{maxWidth: 300, margin: 'auto', marginBottom : 50, marginTop: 50}}>
<a href={go} target='_blank' rel="noreferrer">
	<Button id="voirOffre" type="submit" className="btn-lg" style={{height: 60, backgroundColor: 'rgb(240 0 87)', 
	borderColor: 'rgb(240 0 87)', color: 'white', fontSize: '1.25rem',
	fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
	}}>Je veux en savoir + <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Button>
	</a>
	</div>


<Link to='/banque-pro/'> Comparez les offres de banque pros qui acceptent les dépôts de chèques</Link>


<h2>Ouvrir un compte Qonto</h2>
<h3>Qui peut ouvrir un compte chez Qonto ?</h3>
<p>Qonto est ouvert aux sociétés immatriculés en France de type :</p>

<ul>
	<li>SA</li>
	<li>SAS</li>
	<li>SASU</li>
	<li>SC</li>
	<li>SCI</li>
	<li>EURL</li>
	<li>Professions libérales</li>
	<li>Micro-entreprises</li>
</ul>


<h3>Comment ouvrir un compte Qonto ?</h3>
<p>
L’ouverture du compte prend 15 minutes et est 100% en ligne. La validation du compte quant à elle prend 24 heures. Pour les nouvelles sociétés, vous recevrez l’attestation de dépôts de fonds sous 72 heures.
</p><p>
Justificatifs nécessaires :
</p>
<ul>
	<li>Pièce d’identité des dirigeants et actionnaires</li>
	<li>Justificatif de domicile des dirigeants et actionnaires</li>
</ul>

<p>Qonto offre une période d’essai avec 30 jours gratuits sans engagement.</p>


<div style={{maxWidth: 300, margin: 'auto', marginBottom : 50, marginTop: 50}}>
<a href={go} target='_blank' rel="noreferrer">
	<Button id="voirOffre" type="submit" className="btn-lg" style={{height: 60, backgroundColor: 'rgb(240 0 87)', 
	borderColor: 'rgb(240 0 87)', color: 'white', fontSize: '1.25rem',
	fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
	}}>Aller sur le site <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Button>
	</a>
	</div>

	<h2>Pouvez-vous faire confiance à Qonto ?</h2>
<h3>Qonto est-elle une banque ?</h3>
<p>
Qonto est une Néobanque. C’est un établissement de paiement et non un établissement de crédit comme une banque traditionnelle. À ce titre, votre compte Qonto ne peut pas être débiteur et vous ne pourrez pas déposer d’espèces.
</p>
<h3>La néobanque Qonto est-elle fiable ?</h3>
<p>
Les fonds des clients de Qonto sont sécurisés par le Crédit Mutuel Arkea. Ils sont couvert par le Fonds de Garantie des Dépôts et de Résolution (FGDR) à hauteur de 100 000€.
</p>
<h3>
Tous les avis Qonto  (14/01/2020)</h3>
<p>
Qonto disposent de 65 000 clients actifs avec des avis allant de 4,5 à 4,8/5.
</p>
<ul>
	<li>Avis App Store : 4,8/5</li>
	<li>Avis Google Play Store : 4,7/5</li>
	<li>Avis TrustPilot :  4,5/5</li>
</ul>

<h3>Qonto dans la presse</h3>
<p>
Pour aider à son développement, Qonto a actuellement (14/01/20) levé 32 millions d’euros auprès de Valar et Alven pour renforcer sa présence en Espagne, Allemagne et Italie.
</p>



	
<div style={{maxWidth: 300, margin: 'auto', marginBottom : 50, marginTop: 50}}>
<a href={go} target='_blank' rel="noreferrer">
	<Button id="voirOffre" type="submit" className="btn-lg" style={{height: 60, backgroundColor: 'rgb(240 0 87)', 
	borderColor: 'rgb(240 0 87)', color: 'white', fontSize: '1.25rem',
	fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
	}}>Ouvrir un compte <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Button>
	</a>
	</div>

		</Post>
		</Container>
		</Section>
		<Footer/>
      </PageWrapper>
    </>
    )
}

export default avis
